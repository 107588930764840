import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/login'
import System from '@/views/system'
import Erro404 from '@/views/erro404'
import Dashboard from '@/views/dashboard'
import Links from '@/views/links'
import Financeiro from '@/views/financeiro'

import Perfil from '@/views/perfil'
import Conheca from '@/views/sobre'
import Sobre from '@/views/sobreApp'

import TreinosEscolha from '@/views/treinos/index'
import TreinosLista from '@/views/treinos/list'
import TreinosInfo from '@/views/treinos/info'

import AvaliacoesList from '@/views/avaliacoes/index'
import AvaliacoesInfo from '@/views/avaliacoes/info'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/erro404',
    name: 'Erro404',
    component: Erro404,
  },
  {
    path: '/',
    component: System,
    children: [
      // Dashboard
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
      },
      // Links
      {
        path: '/menu',
        name: 'Links',
        component: Links,
      },
      // Financeiro
      {
        path: '/financeiro',
        name: 'Financeiro',
        component: Financeiro,
      },
      // Treinos
      {
        path: '/treinos',
        name: 'Escolha Tipo de Treino',
        component: TreinosEscolha,
      },
      {
        path: '/treinos/list/:id',
        name: 'Lista de Treinos',
        component: TreinosLista,
      },
      {
        path: '/treinos/info/:id',
        name: 'Treinos - Exercicios',
        component: TreinosInfo,
      },
      // Avaliações
      {
        path: '/avaliacoes',
        name: 'Lista de Avaliacoes',
        component: AvaliacoesList,
      },
      {
        path: '/avaliacoes/info/:id',
        name: 'Informações da Avaliacoes',
        component: AvaliacoesInfo,
      },
      // Links
      {
        path: '/sobre',
        name: 'Sobre o App',
        component: Sobre,
      },
      {
        path: '/conheca',
        name: 'Conheça-nos',
        component: Conheca,
      },
      {
        path: '/perfil',
        name: 'Perfil Pessoal',
        component: Perfil,
      },
    ],
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    return { x: 0, y: 0 }
  },
})

export default router
