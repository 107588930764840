<template>
  <v-app id="login">
    <v-container id="userLogin" style="height: 100vh">
      <v-row align="center" justify="center" style="height: 100%">
        <v-col cols="12" md="5">
          <v-card>
            <v-card-text>
              <div class="py-5 px-10 white rounded-lg elevation-10 mb-2">
                <v-img src="/img/Logo_Dash.png" />
              </div>
              <div class="mt-5 mb-5 font-weight-bold text-center">
                Acessar App
              </div>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="submit">
                  <v-container class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Código"
                      rules="required"
                    >
                      <v-text-field
                        v-model="codigo"
                        v-mask="'AAA##'"
                        label="Código"
                        name="Código"
                        prepend-icon="fas fa-keyboard"
                        :error-messages="errors"
                        clearable
                        required
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      rules="required|min:6"
                    >
                      <v-text-field
                        v-model="password"
                        label="Senha"
                        prepend-icon="fas fa-key"
                        :error-messages="errors"
                        :value="password"
                        :append-icon="value ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        :type="value ? 'password' : 'text'"
                        required
                        @click:append="() => (value = !value)"
                      />
                    </validation-provider>

                    <v-btn
                      :disabled="invalid"
                      type="submit"
                      color="teal darken-1"
                      class="mr-0"
                      block
                      outlined
                      large
                    >
                      Entrar
                      <v-icon right dark>
                        fa fa-paper-plane
                      </v-icon>
                    </v-btn>
                  </v-container>
                </v-form>
              </validation-observer>
              <p class="mt-4 text-center">
              <small>
              <a
                href="https://healthfitconnect.com.br/politica-de-privacidade/"
                rel="noopener"
                target="_blank"
                v-text="'Política de Privacidade'"
              /> | 
              <a
                href="https://healthfitconnect.com.br/termos-e-condicoes/"
                rel="noopener"
                target="_blank"
                v-text="'Termos e Condições'"
              />
              </small>
            </p>
              <p class="mt-1 text-center" style="line-height: 14px">
                <small>
                  &copy; {{ ano }} | Versão {{ version }}<br />
                  <a href="https://www.ateliesolutions.com.br" target="_blank">
                    by
                    <strong>Ateliê Solutions</strong>
                  </a>
                </small>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    codigo: "",
    password: "",
    value: String,
    version: "",
    ano: ""
  }),
  created() {
    this.version = this.$version;
    this.ano = this.$moment().format("YYYY");
    const user = localStorage.getItem("user");
    if (user) {
      this.$router.push("/");
    }
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.$swal({
          title: "Validando suas credenciais!",
          html: "Aguarde...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          }
        });

        this.$http
          .post(this.$urlAPI + "auth/login", {
            login: this.codigo,
            password: this.password
          })
          .then(response => {
            this.$swal.close();
            if (response.data.status) {
              if (response.data.usuario) {
                if (response.data.usuario.tipo === "A") {
                  /* login com sucesso */
                  localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.usuario)
                  );
                  this.$router.go("/");
                } else {
                  this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Login inválido! Usuário não conferem!"
                  });
                }
              } else {
                this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text:
                    "Este usuário esta inativo no sistema. Entre em contato com administrador responsável!"
                });
              }
            } else {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Login inválido! Usuário ou senha não conferem!"
              });
            }
          })
          .catch(e => {
            this.$swal({
              icon: "error",
              title: "Erro!",
              text: e
            });
          });
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
#login {
  background-image: url(../assets/login.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
</style>
