<template>
  <div>
    <v-container>
      <v-alert
        v-if="pagamento.status"
        v-model="alert"
        border="left"
        close-text="Fechar notificação"
        color="amber accent-4"
        type="warning"
        dark
        dismissible
      >
        <strong>ATENÇÃO!</strong><br>
        Você esta com a mensalidade atrasada!<br>Sua última mensalidade paga foi <strong>{{ pagamento.ultima }}</strong>.<br>Em breve seu acesso será bloqueado!
      </v-alert>
      <v-alert
        v-model="alertData"
        border="left"
        close-text="Fechar notificação"
        color="blue accent-4"
        type="info"
        dark
        dismissible
      >
        <strong>ATENÇÃO!</strong><br>
        Sua mensalidade vence em <strong>{{ diferenceDate }}</strong> dias.<br>
        Data: <strong>{{ diaVencimento }}/{{ mesAnoAtual }}</strong>
      </v-alert>
    </v-container>
    <v-card>
      <v-card-text class="text-center">
        <h5 class="mb-1 mt-3 purple--text">
          {{ academia.tipo_company == 'A' ? 'Academia' : 'Personal Tainer' }}
        </h5>

        <h2 class="font-weight-light mb-3 black--text">
          {{ academia.tipo_pessoa == 'F' ? academia.nome : academia.razao_social }}
        </h2>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-btn
          color="info"
          :href="'tel:' + (academia.telefone == '' ? academia.celular.replace(/[^0-9]/g, '') : academia.telefone.replace(/[^0-9]/g, ''))"
          width="52%"
        >
          <v-icon
            left
            small
          >
            fas fa-phone-alt
          </v-icon>
          {{ (academia.telefone == '' ? academia.celular : academia.telefone) | maskPhone }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="mx-1 white--text"
          color="green darken-1"
          :href="'https://api.whatsapp.com/send?phone=55' + academia.celular.replace(/[^0-9]/g, '')"
          target="_blank"
          rel="noreferrer"
          width="44%"
        >
          <v-icon
            left
            small
          >
            fab fa-whatsapp
          </v-icon>
          WhatsApp
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="mt-5">
      <v-card
        class="mx-auto text-center"
        max-width="600"
      >
        <v-card-text>
          <h3>Acompanhe sua evolução</h3>
        </v-card-text>

        <v-divider />

        <v-card-text>
          <v-sheet color="rgba(0, 0, 0, .02)">
            <line-chart
              v-if="loaded"
              :chartdata="data"
              :options="options"
            />
          </v-sheet>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
  import LineChart from './Chart.vue'

  export default {
    components: { LineChart },
    data () {
      return {
        alert: true,
        academia: {},
        diaVencimento: 0,
        mesAnoAtual: 0,
        alertData: false,
        diferenceDate: 0,
        pagamento: {},
        loaded: false,
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Comparativo mediante suas avaliações',
          },
          tooltips: {
            mode: 'label',
          },
          elements: {
            line: {
              fill: true,
            },
          },
        },
      }
    },
    async created () {
      const user = JSON.parse(localStorage.getItem('profile'))
      this.academia = user.academia
      this.diaVencimento = user.dia_vencimento
      this.pagamento = user.payment
      this.mesAnoAtual = this.$moment().format('MM/YYYY')

      this.data.labels = await user.av_realizacao
      this.data.datasets = await [{
        label: 'Peso (Kg)',
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
        data: user.av_peso,
      }, {
        label: 'Percentual de Gordura (%)',
        borderColor: 'rgb(103, 58, 183)',
        tension: 0.1,
        data: user.av_perc,
      }, {
        label: 'Massa Muscular (Kg)',
        borderColor: 'rgb(255, 0, 183)',
        tension: 0.1,
        data: user.av_muscular,
      }]

      const dataVencimento = this.$moment(this.$moment().format('YYYY-MM') + '-' + user.dia_vencimento)
      const dataAtual = this.$moment()

      const diferenceDate = dataVencimento.diff(dataAtual, 'days')
      this.diferenceDate = diferenceDate + 1
      if (diferenceDate >= 0 && diferenceDate <= 10) {
        this.alertData = true
      }

      this.loaded = true
    },
  }
</script>
