<template>
  <div>
    <headerPage title="Acesse" />
    <v-list class="py-0">
      <v-list-item-group
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="redirecionar(item.link, item.target)"
        >
          <v-list-item-icon>
            <v-icon
              color="deep-purple darken-4"
              v-text="item.icon"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="sair">
          <v-list-item-icon>
            <v-icon
              color="deep-purple darken-4"
              v-text="'fas fa-sign-out-alt'"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'Sair'" />
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </v-list-item-group>
      <v-subheader>
        <h4 class="blue-grey--text text--darken-1">
          Versão: 1.0.0
        </h4>
      </v-subheader>
    </v-list>
  </div>
</template>

<script>
  export default {
    components: {
      headerPage: () => import('@/components/headerPage'),
    },
    data: () => ({
      items: [],
    }),
    created () {
      const user = JSON.parse(localStorage.getItem('profile'))

      this.items = [
        { text: 'Perfil', icon: 'far fa-user', link: '/perfil', target: '_self' },
        { text: 'Conheça-nos', icon: 'far fa-address-card', link: '/conheca', target: '_self' },
        { text: 'Fale Conosco', icon: 'fab fa-whatsapp', link: 'https://api.whatsapp.com/send?phone=5535999762003' + user.academia.celular, target: '_blank' },
        { text: 'Política de Privacidade', icon: 'fas fa-user-shield', link: 'https://healthfitconnect.com.br/politica-de-privacidade/', target: '_blank' },
        { text: 'Termos de Uso', icon: 'fa fa-book', link: 'https://healthfitconnect.com.br/termos-e-condicoes/', target: '_blank' },
        { text: 'Sobre o App', icon: 'far fa-smile', link: '/sobre', target: '_self' },
      ]
    },
    methods: {
      redirecionar (link, target) {
        if (target === '_self') {
          this.$router.push(link)
        } else {
          window.open(link, '_blank')
        }
      },
      sair () {
        this.$swal({
          title: 'Sair do Sistema?',
          text: 'Deseja realmente efetuar esta ação?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$http.get(this.$urlAPI + 'auth/logout', { headers: { authorization: 'Bearer ' + this.$store.getters.GET_TOKEN } })
              .then(response => {
                if (response.data.status) {
                  localStorage.clear()
                  this.$router.go('/')
                }
              })
              .catch(e => {
                console.log(e)
                this.$swal({
                  icon: 'error',
                  title: 'Erro!',
                  text: e,
                })
              })
          }
        })
      },
    },
  }
</script>
