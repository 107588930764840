<template>
  <dashboard-core-view v-if="render" />
</template>

<script>
  export default {
    name: 'SystemIndex',
    components: {
      DashboardCoreView: () => import('@/components/core/View'),
    },
    data: () => ({
      render: true,
    }),
    created () {
      const user = localStorage.getItem('user')
      if (user) {
        if (navigator.onLine) {
          const userAux = JSON.parse(user)
          this.$http.get(this.$urlAPI + 'auth/user', {
            headers: {
              authorization: 'Bearer ' + userAux.token,
            },
          }).then(async response => {
            if (response.data.user) {
              await localStorage.setItem('profile', JSON.stringify(response.data.user))
              // this.render = true
            } else {
              this.$swal({
                icon: 'error',
                title: 'Erro!',
                text: response.data.error,
              })
              localStorage.clear()
              window.location.reload(true)
            }
          }).catch(e => {
            console.log(e)
          })
        } else {
          return true
        }
      } else {
        this.$router.push('/login')
      }
    },
  }
</script>
