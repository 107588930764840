<template>
  <div>
    <headerPage title="Financeiro" />
    <v-text-field
      v-model="search"
      append-icon="fas fa-search"
      label="Pesquisar"
      outlined
    />
    <v-data-table
      :headers="headers"
      :items="itens"
      :search="search"
      :mobile-breakpoint="0"
    >
      <template
        v-slot:item.ref="{ item }"
      >
        <span style="text-transform: uppercase;">
          <template v-if="item.tipo == 'M'">
            {{ item.mes_referencia }}/{{ item.ano_referencia }}
          </template>
          <template v-else>
            {{ item.descricao }}
          </template>
        </span>
      </template>

      <template v-slot:item.valor="{ item }">
        {{ item.valor | currency }}
        <small style="color: blue; display: block;">
          {{ item.tipo_pagamento }}
        </small>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | moment('DD/MM/YYYY') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    components: {
      headerPage: () => import('@/components/headerPage'),
    },
    data () {
      return {
        search: '',
        headers: [
          { text: 'Referência', align: 'center', sortable: true, value: 'ref', class: 'teal--text' },
          { text: 'Valor', align: 'center', value: 'valor', class: 'teal--text' },
          { text: 'Data', align: 'center', sortable: true, value: 'created_at', class: 'teal--text' },
        ],
        itens: [],
      }
    },
    created () {
      if (navigator.onLine) {
        this.loadItens()
      } else {
        this.itens = JSON.parse(localStorage.getItem('payments'))
      }
    },
    methods: {
      loadItens () {
        this.$http.get(this.$urlAPI + 'app/payments', {
          headers: {
            authorization: 'Bearer ' + this.$store.getters.GET_TOKEN,
          },
        }).then(response => {
          localStorage.setItem('payments', JSON.stringify(response.data))
          this.itens = response.data
        }).catch(e => {
          console.log(e)
        })
      },
    },
  }
</script>

<style>
  @media (max-width: 600px) {
    .v-data-footer__icons-before{
      width: 50%;
      text-align: right;
    }
    .v-data-footer__icons-after{
      width: 50%;
    }
  }
</style>
