<template>
  <div>
    <headerPage title="Conheça-nos" />
    <v-container>
      <v-card>
        <v-card-text class="text-center">
          <v-avatar
            class="elevation-10"
            color="blue-grey lighten-5"
            size="164"
          >
            <v-icon
              v-if="academia.icone == null"
              size="100"
              color="teal darken-2"
            >
              fas fa-drafting-compass
            </v-icon>
            <v-img
              v-if="academia.icone != null"
              :src=" academia.icone"
              contain
            />
          </v-avatar>

          <h5 class="mb-1 mt-3 grey--text">
            {{ academia.tipo_company == 'A' ? 'Academia' : 'Personal Tainer' }}
          </h5>

          <h2 class="font-weight-light mb-3 black--text">
            {{ academia.tipo_pessoa == 'F' ? academia.nome : academia.razao_social }}
          </h2>

          <p class="font-weight-light grey--text">
            {{ academia.descricao }}
          </p>

          <address class="mb-3">
            <v-icon>fas fa-map-marker-alt</v-icon> {{ academia.logradouro }}, {{ academia.numero }}, {{ academia.bairro }}, {{ academia.cidade }}/{{ academia.estado }} - {{ academia.cep | maskCEP }}<br>
          </address>

          <v-btn
            class="my-1"
            color="light"
            :href="academia.url_maps"
            target="_blank"
          >
            <v-icon left>
              fas fa-map-marker-alt
            </v-icon>
            Visualize no Mapa
          </v-btn>

          <v-btn
            class="my-1"
            color="light"
            :href="'tel:' + (academia.telefone == '' ? academia.celular.replace(/[^0-9]/g, '') : academia.telefone.replace(/[^0-9]/g, ''))"
          >
            <v-icon left>
              fas fa-phone-alt
            </v-icon>
            {{ (academia.telefone == '' ? academia.celular : academia.telefone) | maskPhone }}
          </v-btn>
          <v-btn
            class="my-1"
            color="light"
            :href="academia.url_site"
            target="_blank"
          >
            <v-icon left>
              fas fa-link
            </v-icon>
            Acesse o Site
          </v-btn>
          <div class="my-3">
            <v-btn
              class="mx-1 white--text"
              color="info"
              :href="academia.url_facebook"
              target="_blank"
            >
              <v-icon size="24px">
                fab fa-facebook
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-1 white--text"
              color="brown"
              :href="academia.url_instagram"
              target="_blank"
            >
              <v-icon size="24px">
                fab fa-instagram
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-1 white--text"
              color="success"
              :href="'https://api.whatsapp.com/send?phone=55' + academia.celular.replace(/[^0-9]/g, '')"
              target="_blank"
            >
              <v-icon size="24px">
                fab fa-whatsapp
              </v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  export default {
    components: {
      headerPage: () => import('@/components/headerPage'),
    },
    data () {
      return {
        academia: {},
        urlIMG: '',
      }
    },
    created () {
      this.urlIMG = this.$urlIMG
      const user = JSON.parse(localStorage.getItem('profile'))
      this.academia = user.academia
    },
  }
</script>
