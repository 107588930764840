import Vue from 'vue'

import Axios from 'axios'
import VueAxios from 'vue-axios'
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'

Vue.use(VueAxios, Axios)

Axios.interceptors.request.use(config => {
  config.headers['Cache-Control'] = 'no-store'
  config.headers.Pragma = 'no-cache'
  return config
})

Vue.use(loadProgressBar)
