import Vue from 'vue'

import App from './App.vue'
import './registerServiceWorker'

import router from './router'
import store from './store'

// Plugins
import vuetify from './plugins/vuetify'
import './plugins/vee-validate'
import './plugins/axios'
import './plugins/money'

// Bibliotecas
import VueMoment from 'vue-moment'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTheMask from 'vue-the-mask'

// Importação de Css necessários
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'sweetalert2/dist/sweetalert2.min.css'

const moment = require('moment')
require('moment/locale/pt-br')
Vue.use(VueMoment, {
  moment,
})

const options = {
  confirmButtonColor: '#9C27b0',
  cancelButtonColor: '#78909c',
}
Vue.use(VueSweetalert2, options)
Vue.use(VueTheMask)

Vue.config.productionTip = true
Vue.prototype.$version = '1.0.1'

// Vue.prototype.$urlAPI = 'http://localhost:8000/api/'
Vue.prototype.$urlAPI = 'https://api.healthfitconnect.com.br/api/'
Vue.prototype.$urlIMG = 'https://api.healthfitconnect.com.br/'

// Mascaras
Vue.filter('maskCPF', function (cpf = null) {
  if (cpf === null) {
    return ''
  } else {
    return cpf.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
})

Vue.filter('maskPhone', function (tel = null) {
  if (tel === null) {
    return ''
  } else {
    if (tel.length === 11) {
      return tel.replace(/[^0-9]/g, null).replace(/(\d{2})(\d)(\d{4})(\d{4})$/, '($1) $2 $3-$4')
    } else {
      return tel.replace(/[^0-9]/g, null).replace(/(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
    }
  }
})

Vue.filter('maskCEP', function (cep = null) {
  if (cep === null) {
    return ''
  } else {
    return cep.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{3})(\d{3})$/, '$1.$2-$3')
  }
})

Vue.filter('currency', function (value = 0) {
  const val = (value / 1).toFixed(2).replace('.', ',')
  return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})

Vue.filter('truncate', function (value = '') {
  if (value && value.length > 20) {
    value = value.substring(0, 80) + '...'
  }
  return value
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
