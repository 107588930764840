<template>
  <div>
    <headerPage title="Avaliações" />
    <v-list two-line>
      <div
        v-for="(item, key) in itens"
        :key="key"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Aval.: <strong>{{ item.avaliador }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-icon
                left
                small
              >
                fas fa-calendar
              </v-icon>
              {{ item.data_realizacao | moment('DD/MM/YYYY') }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              :to="'/avaliacoes/info/' + key"
              icon
            >
              <v-icon color="secondary">
                fas fa-chevron-right
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
      </div>
    </v-list>
  </div>
</template>

<script>
  export default {
    components: {
      headerPage: () => import('@/components/headerPage'),
    },
    data: () => ({
      itens: [],
    }),
    created () {
      if (navigator.onLine) {
        this.loadItens()
      } else {
        this.itens = JSON.parse(localStorage.getItem('assessments'))
      }
    },
    methods: {
      loadItens () {
        this.$http.get(this.$urlAPI + 'app/assessments', {
          headers: {
            authorization: 'Bearer ' + this.$store.getters.GET_TOKEN,
          },
        }).then(response => {
          localStorage.setItem('assessments', JSON.stringify(response.data))
          this.itens = response.data
        }).catch(e => {
          console.log(e)
        })
      },
    },
  }
</script>
