import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    profile: localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null,
  },
  getters: {
    GET_TOKEN: state => {
      return state.user.token
    },
    GET_ID_USER: state => {
      return state.user.id
    },
    GET_TYPE: state => {
      return state.user.tipo
    },
    GET_PROFILE: state => {
      return state.profile
    },
    GET_ID_USER_PROFILE: state => {
      return state.profile.id
    },
  },
  mutations: {
    SET_USER (state, payload) {
      state.user = payload
    },
    SET_PROFILE (state, payload) {
      state.profile = payload
    },
  },
})
