<template>
  <div>
    <headerPage title="Informações do Treino" :btn="true" />

    <v-btn
      color="blue-grey darken-2"
      class="white--text mb-2"
      large
      block
      @click="salvePdf"
    >
      <v-icon left dark small>
        fas fa-file-pdf
      </v-icon>
      Download Treino
    </v-btn>

    <p class="px-5 py-3 mb-0 amber accent-1">
      <small><strong>Legenda:</strong></small
      ><br />
      <v-icon x-small color="red" class="mr-2"> fas fa-retweet </v-icon>Séries
      <v-icon x-small color="blue" class="mx-2"> fas fa-sync </v-icon
      >Repetições/Velocidade<br />
      <v-icon x-small color="green" class="mr-2"> fas fa-balance-scale </v-icon
      >Carga
      <v-icon x-small color="purple" class="mx-2">
        fas fa-hourglass-half </v-icon
      >Tempo
    </p>

    <v-container>
      <h3>
        Nível do Treino:
        <strong>{{
          treino.nivel == "I"
            ? "Iniciante"
            : treino.nivel == "T"
            ? "Intermediário"
            : "Avançado"
        }}</strong>
      </h3>
      <h3>
        Programa:
        <strong>{{ treino.programa }}</strong>
      </h3>
    </v-container>

    <v-divider class="my-3" />
    <v-alert
      border="left"
      colored-border
      color="templatel accent-4"
      elevation="2"
    >
      <p class="mb-1">
        <strong>Alongamentos:</strong>
      </p>
      <v-row>
        <v-col cols="6">
          <p class="mb-0">
            <v-icon left small>
              fas fa-retweet
            </v-icon>
            Série: <strong>{{ treino.alongamento_serie }}</strong>
          </p>
        </v-col>
        <v-col cols="6">
          <p class="mb-0">
            <v-icon left small>
              fas fa-hourglass-half
            </v-icon>
            Duração: <strong>{{ treino.alongamento_duracao }}</strong>
          </p>
        </v-col>
      </v-row>
    </v-alert>
    <v-container class="pt-0">
      <h3>Aeróbico:</h3>
    </v-container>
    <v-alert
      v-if="treino.eliptico"
      border="left"
      colored-border
      color="deep-purple accent-4"
      elevation="2"
    >
      <p class="mb-1">
        <strong>Elíptico:</strong>
      </p>
      <v-row>
        <v-col cols="4">
          <v-chip color="red lighten-5" label small style="width: 100%">
            <v-icon x-small color="red" left>
              fas fa-hourglass-half
            </v-icon>
            {{ treino.eliptico_tempo }}
          </v-chip>
        </v-col>
        <v-col cols="4">
          <v-chip color="blue lighten-5" label small style="width: 100%">
            <v-icon x-small color="blue" left>
              fas fa-tachometer-alt
            </v-icon>
            {{ treino.eliptico_velocidade }}
          </v-chip>
        </v-col>
        <v-col cols="4">
          <v-chip color="green lighten-5" label small style="width: 100%">
            <v-icon x-small color="green" left>
              fas fa-balance-scale
            </v-icon>
            {{ treino.eliptico_carga }}
          </v-chip>
        </v-col>
      </v-row>
    </v-alert>
    <v-alert
      v-if="treino.bicicleta"
      border="left"
      colored-border
      color="orange accent-4"
      elevation="2"
    >
      <p class="mb-1">
        <strong>Bicicleta:</strong>
      </p>
      <v-row>
        <v-col cols="4">
          <v-chip color="red lighten-5" label small style="width: 100%">
            <v-icon x-small color="red" left>
              fas fa-hourglass-half
            </v-icon>
            {{ treino.bicicleta_tempo }}
          </v-chip>
        </v-col>
        <v-col cols="4">
          <v-chip color="blue lighten-5" label small style="width: 100%">
            <v-icon x-small color="blue" left>
              fas fa-tachometer-alt
            </v-icon>
            {{ treino.bicicleta_velocidade }}
          </v-chip>
        </v-col>
        <v-col cols="4">
          <v-chip color="green lighten-5" label small style="width: 100%">
            <v-icon x-small color="green" left>
              fas fa-balance-scale
            </v-icon>
            {{ treino.bicicleta_carga }}
          </v-chip>
        </v-col>
      </v-row>
    </v-alert>
    <v-alert
      v-if="treino.esteira"
      border="left"
      colored-border
      color="green accent-4"
      elevation="2"
    >
      <p class="mb-1">
        <strong>Esteira:</strong>
      </p>
      <v-row>
        <v-col cols="4">
          <v-chip color="red lighten-5" label small style="width: 100%">
            <v-icon x-small color="red" left>
              fas fa-hourglass-half
            </v-icon>
            {{ treino.esteira_tempo }}
          </v-chip>
        </v-col>
        <v-col cols="4">
          <v-chip color="blue lighten-5" label small style="width: 100%">
            <v-icon x-small color="blue" left>
              fas fa-tachometer-alt
            </v-icon>
            {{ treino.esteira_velocidade }}
          </v-chip>
        </v-col>
        <v-col cols="4">
          <v-chip color="green lighten-5" label small style="width: 100%">
            <v-icon x-small color="green" left>
              fas fa-balance-scale
            </v-icon>
            {{ treino.esteira_carga }}
          </v-chip>
        </v-col>
      </v-row>
    </v-alert>
    <v-alert
      v-if="treino.obs != '' && treino.obs != null"
      border="left"
      colored-border
      color="grey"
      elevation="2"
    >
      <p>
        Observação: <strong>{{ treino.obs }}</strong>
      </p>
    </v-alert>

    <v-tabs v-model="tab" background-color="deep-purple accent-4" centered dark>
      <v-tab
        v-for="treino in treino.list.ordem"
        :key="treino"
        :href="'#tab-' + treino"
      >
        Treino: <strong class="ml-2">{{ treino }}</strong>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in treino.list.ordem"
        :key="item"
        :value="'tab-' + item"
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <v-card
          v-for="(sequencia, key) in treino.list.workouts[item]"
          :key="key"
          color="deep-purple darken-4 rounded-0"
          elevation="3"
          outlined
        >
          <v-card-title class="py-2 white--text" style="font-size: 16px">
            Sequência de Exercício n<sup>o</sup
            ><strong class="ml-1">{{ key }}</strong>
          </v-card-title>
          <v-card-text class="white pa-0">
            <v-expansion-panels focusable>
              <v-expansion-panel
                v-for="(exercicio, key2) in sequencia"
                :key="key2"
                style="border-bottom: 1px solid rgba(0,0,0,0.2)"
              >
                <v-expansion-panel-header>
                  <v-row no-gutters>
                    <v-col cols="6" class="pb-1">
                      Exercício ({{ key2 + 1 }})<br />
                      <strong>{{ exercicio.nome }}</strong>
                    </v-col>
                    <v-col cols="6" class="pb-1">
                      Categoria<br />
                      <strong>{{ exercicio.categoria }}</strong>
                    </v-col>
                    <v-col cols="4">
                      <v-chip
                        color="red lighten-5"
                        label
                        small
                        style="width: 99%"
                      >
                        <v-icon x-small color="red" left>
                          fas fa-retweet
                        </v-icon>
                        {{ exercicio.serie }}
                      </v-chip>
                    </v-col>
                    <v-col cols="4">
                      <v-chip
                        color="blue lighten-5"
                        label
                        small
                        style="width: 99%"
                      >
                        <v-icon x-small color="blue" left>
                          fas fa-sync
                        </v-icon>
                        {{ exercicio.repticoes }}
                      </v-chip>
                    </v-col>
                    <v-col cols="4">
                      <v-chip
                        color="green lighten-5"
                        label
                        small
                        style="width: 99%"
                      >
                        <v-icon x-small color="green" left>
                          fas fa-balance-scale
                        </v-icon>
                        {{ exercicio.carga }}
                      </v-chip>
                    </v-col>
                    <v-col cols="12" class="mt-1">
                      <v-chip
                        color="purple lighten-5"
                        label
                        small
                        style="width: 99.6%"
                      >
                        <v-icon x-small color="purple" left>
                          fas fa-hourglass-half
                        </v-icon>
                        {{ exercicio.tempo }}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <template v-slot:actions>
                    <v-icon color="secondary">
                      $expand
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="mt-2">
                    <v-text-field
                      v-model="exercicio.carga"
                      class="mt-8"
                      label="Alterar Carga"
                      hint="Click no icone de check para alterar a carga."
                      persistent-hint
                      outlined
                      append-icon="fa fa-check"
                      title="Alterar Carga"
                      @click:append="
                        update(
                          exercicio.carga,
                          exercicio.id,
                          exercicio.serie,
                          exercicio.repticoes,
                          exercicio.sequencia
                        )
                      "
                    />
                    <p>
                      <strong>Categoria:</strong><br />
                      {{ exercicio.desc_categoria }}
                    </p>
                    <p>
                      <strong>Exercício:</strong><br />
                      {{ exercicio.desc_exercicio }}
                    </p>
                    <v-card>
                      <v-img
                        max-width="200"
                        :src="urlIMG + exercicio.imagem"
                        class="grey darken-4 mx-auto"
                      />
                    </v-card>
                    <v-btn
                      v-if="exercicio.video != '' && exercicio.video != null"
                      tile
                      color="red"
                      dark
                      block
                      class="mt-2"
                      @click="abrirmodal(exercicio.video)"
                    >
                      <v-icon left>
                        fas fa-play
                      </v-icon>
                      Visualizar Video
                    </v-btn>
                    <div v-if="exercicio.avaliado == 'N'" class="text-center">
                      <v-divider class="my-2" />
                      <p><strong>Avalie este exercicio!</strong></p>
                      <v-row class="justify-center my-2">
                        <v-btn
                          :id="'btn_' + exercicio.id_exercises + '_1'"
                          class="mx-3"
                          fab
                          @click="
                            avaliar(exercicio.id, exercicio.id_exercises, 1)
                          "
                        >
                          <v-icon color="red" size="30">
                            far fa-flushed
                          </v-icon>
                        </v-btn>
                        <v-btn
                          :id="'btn_' + exercicio.id_exercises + '_2'"
                          class="mx-3"
                          fab
                          @click="
                            avaliar(exercicio.id, exercicio.id_exercises, 2)
                          "
                        >
                          <v-icon color="amber" size="30">
                            far fa-meh
                          </v-icon>
                        </v-btn>
                        <v-btn
                          :id="'btn_' + exercicio.id_exercises + '_3'"
                          class="mx-3"
                          fab
                          @click="
                            avaliar(exercicio.id, exercicio.id_exercises, 3)
                          "
                        >
                          <v-icon color="green" size="30">
                            far fa-smile
                          </v-icon>
                        </v-btn>
                        <v-btn
                          :id="'btn_' + exercicio.id_exercises + '_4'"
                          class="mx-3"
                          fab
                          @click="
                            avaliar(exercicio.id, exercicio.id_exercises, 4)
                          "
                        >
                          <v-icon color="purple" size="30">
                            far fa-smile-beam
                          </v-icon>
                        </v-btn>
                        <v-btn
                          :id="'btn_' + exercicio.id_exercises + '_5'"
                          class="mx-3"
                          fab
                          @click="
                            avaliar(exercicio.id, exercicio.id_exercises, 5)
                          "
                        >
                          <v-icon color="blue" size="30">
                            far fa-laugh-squint
                          </v-icon>
                        </v-btn>
                      </v-row>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="dialogVideo">
      <v-card>
        <v-card-text class="py-0 px-0">
          <iframe
            :src="'https://www.youtube.com/embed/' + video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style="width: 100%; height: 400px"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-center">
          <v-spacer />
          <v-btn color="grey darken-1" @click="fechar">
            <v-icon left>
              fas fa-times
            </v-icon>
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- // eslint-disab  le-next-line -->
    <div
      id="treino"
      ref="document"
      style="padding: 0 0.5cm; margin: 0 auto; width: 100%;"
      v-if="renderTreino"
    >
      <table>
        <tbody>
          <td style="width: 10%">
            <!-- // eslint-disable-next-line -->
            <img :src="urlIMG + academia.logotipo" style="width: 100%" />
          </td>
          <td style="width: 40%">
            <p style="font-size: 9pt; margin: 0 0 0 10px;">
              {{
                academia.tipo_pessoa == "F"
                  ? academia.nome
                  : academia.razao_social
              }}<br />
              <small
                >{{
                  academia.tipo_company == "A" ? "Academia" : "Personal Trainer"
                }}
                -
                <strong
                  >{{ academia.cidade }}/{{ academia.estado }}</strong
                ></small
              >
            </p>
            <v-divider style="margin: 5px 10px" />
            <p style="font-size: 9pt; margin: 0 0 0 10px;">
              <strong>{{ aluno.nome }}</strong> - {{ aluno.cpf | maskCPF
              }}<br />
              Nível do Treino:
              <strong>{{
                treino.nivel == "I"
                  ? "Iniciante"
                  : treino.nivel == "T"
                  ? "Intermediário"
                  : "Avançado"
              }}</strong>
              | Programa: <strong>{{ treino.programa }}</strong>
            </p>
          </td>
          <td style="width: 50%; border: 1px solid #999;">
            <p style="font-size: 9pt;margin: 0.2cm">
              Observação: <strong>{{ treino.obs }}</strong>
            </p>
          </td>
        </tbody>
      </table>
      <v-divider style="margin: 5px 0" />
      <table>
        <tbody>
          <td>
            <p style="font-size: 9pt; margin: 0 10px 0 0">
              <strong>Alongamentos:</strong><br />
              Série: <strong>{{ treino.alongamento_serie }}</strong> | Duração:
              <strong>{{ treino.alongamento_duracao }}</strong>
            </p>
          </td>
          <td>
            <p
              v-if="treino.eliptico"
              style="font-size: 9pt; margin: 0 10px 0 0"
            >
              <strong>Elíptico:</strong><br />
              Tempo: <strong>{{ treino.eliptico_tempo }}</strong> | Vel.:
              <strong>{{ treino.eliptico_velocidade }}</strong> | Carga:
              <strong>{{ treino.eliptico_carga }}</strong>
            </p>
          </td>
          <td>
            <p
              v-if="treino.bicicleta"
              style="font-size: 9pt; margin: 0 10px 0 0"
            >
              <strong>Bicicleta:</strong><br />
              Tempo: <strong>{{ treino.bicicleta_tempo }}</strong> | Vel.:
              <strong>{{ treino.bicicleta_velocidade }}</strong> | Carga:
              <strong>{{ treino.bicicleta_carga }}</strong>
            </p>
          </td>
          <td>
            <p v-if="treino.esteira" style="font-size: 9pt; margin: 0 10px 0 0">
              <strong>Esteira:</strong><br />
              Tempo: <strong>{{ treino.esteira_tempo }}</strong> | Vel.:
              <strong>{{ treino.esteira_velocidade }}</strong> | Carga:
              <strong>{{ treino.esteira_carga }}</strong>
            </p>
          </td>
        </tbody>
      </table>
      <v-divider style="margin: 5px 0" />
      <div
        v-for="itemImp in treino.list.ordem"
        :key="itemImp"
        style="break-inside: avoid;"
      >
        <h6>
          Treino: <strong>{{ itemImp }}</strong>
        </h6>
        <v-divider style="margin: 5px 0" />
        <div style="display: flex; flex-wrap: wrap;">
          <div
            v-for="(sequenciaImp, key3) in treino.list.workouts[itemImp]"
            :key="key3"
            style="width: 33.33% !important;"
          >
            <v-card
              style="background-color: #311b92 !important; border-color: #311b92 !important; margin-bottom: 5px; break-inside: avoid;"
            >
              <v-card-title style="line-height: 1pt; padding: 8px 10px;">
                <p style="font-size: 9pt; color: #ffffff; margin: 0;">
                  Sequência de Exercício n<sup>o</sup
                  ><strong class="ml-1">{{ key3 }}</strong>
                </p>
              </v-card-title>
              <v-card-text
                style="background-color: #ffffff !important;  padding: 5px 10px 10px;"
              >
                <div v-for="(exercicio, key2) in sequenciaImp" :key="key2">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <p
                        style="font-size: 9pt; line-height: 9pt; margin: 5px 0"
                      >
                        Exercício ({{ key2 + 1 }}):
                        <strong>{{ exercicio.nome }}</strong
                        ><br />
                        Categoria: <strong>{{ exercicio.categoria }}</strong>
                      </p>
                    </v-col>
                    <v-col cols="4" style="height: 16px;">
                      <v-chip
                        color="red lighten-5"
                        label
                        small
                        style="width: 100%; height: 15px; line-height: 9pt;"
                      >
                        <p style="font-size: 9pt; margin: 0; line-height: 9pt;">
                          Série: <strong>{{ exercicio.serie }}</strong>
                        </p>
                      </v-chip>
                    </v-col>
                    <v-col cols="4" style="height: 16px;">
                      <v-chip
                        color="blue lighten-5"
                        label
                        small
                        style="width: 100%; height: 15px; line-height: 9pt;"
                      >
                        <p style="font-size: 9pt; margin: 0; line-height: 9pt;">
                          Rep.: <strong>{{ exercicio.repticoes }}</strong>
                        </p>
                      </v-chip>
                    </v-col>
                    <v-col cols="4" style="height: 16px;">
                      <v-chip
                        color="green lighten-5"
                        label
                        small
                        style="width: 100%; height: 15px; line-height: 9pt;"
                      >
                        <p style="font-size: 9pt; margin: 0; line-height: 9pt;">
                          Carga: <strong>{{ exercicio.carga }}</strong>
                        </p>
                      </v-chip>
                    </v-col>
                    <v-col cols="12" style="height: 16px; margin-bottom: 5px">
                      <v-chip
                        color="purple lighten-5"
                        label
                        small
                        style="width: 100%; height: 15px; line-height: 9pt;"
                      >
                        <p style="font-size: 9pt; margin: 0; line-height: 9pt;">
                          Tempo: <strong>{{ exercicio.tempo }}</strong>
                        </p>
                      </v-chip>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <v-divider style="margin: 5px 0" />
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  components: {
    headerPage: () => import("@/components/headerPage")
  },
  data: () => ({
    dialogVideo: false,
    treino: {},
    tab: false,
    renderTreino: false,
    video: "",
    academia: {},
    aluno: {},
    urlIMG: ""
  }),
  created() {
    this.urlIMG = this.$urlIMG;
    const localItem = JSON.parse(localStorage.getItem("trainings"));
    this.treino = localItem.list[this.$route.params.id];

    const user = JSON.parse(localStorage.getItem("profile"));
    this.academia = user.academia;

    this.aluno = user;
  },
  methods: {
    async salvePdf() {
      this.renderTreino = await true

      this.$swal({
        title: "Gerando arquivo PDF de seu treino!",
        html: "Aguarde...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        }
      });

      await new Promise(r => setTimeout(r, 1000));
      const element = await document.getElementById('treino');
      console.log('...')
      await html2pdf(element, {
        margin: [10, 10, 15, 10],
        filename: "treino.pdf",
        image: {
          type: "png",
          quality: 1
        },
        pagebreak: {
          mode: ["css", "legacy"]
        },
        html2canvas: {
          dpi: 200,
          letterRendering: true,
          useCORS: true,
          scale: 2
        },
        jsPDF: {
          unit: "pt",
          format: "a4",
          orientation: "landscape"
        }
      });

      await this.$swal.close()
      this.renderTreino = false
    },
    async abrirmodal(video) {
      this.video = await video;
      this.dialogVideo = true;
    },
    fechar() {
      this.dialogVideo = false;
      this.video = "";
    },
    avaliar(exercicioTreino, exercicio, value) {
      if (navigator.onLine) {
        this.$swal({
          title: "Enviando informações!",
          html: "Aguarde...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          }
        });

        this.$http
          .post(
            this.$urlAPI + "app/ratings",
            {
              treino: exercicioTreino,
              exercicio: exercicio,
              aluno: this.$store.getters.GET_ID_USER_PROFILE,
              valor: value
            },
            {
              headers: {
                authorization: "Bearer " + this.$store.getters.GET_TOKEN
              }
            }
          )
          .then(response => {
            this.$swal.close();
            if (response.data.status) {
              for (let i = 1; i <= 5; i++) {
                if (i !== value) {
                  document.getElementById(
                    "btn_" + exercicio + "_" + i
                  ).disabled = true;
                  document.getElementById(
                    "btn_" + exercicio + "_" + i
                  ).className += " v-btn--disabled";
                }
              }
            } else {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Algo deu errado ao salvar! Tente novamente mais tarde."
              });
            }
          })
          .catch(e => {
            this.$swal({
              icon: "error",
              title: "Erro!",
              text: e
            });
          });
      } else {
        this.$swal({
          icon: "info",
          title: "Atenção!",
          text: "Você deve estar online para efetuar a avaliação!"
        });
      }
    },
    update(carga, id, serie, repticoes, sequencia) {
      if (navigator.onLine) {
        this.$swal({
          title: "Enviando informações!",
          html: "Aguarde...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          }
        });

        this.$http
          .put(
            this.$urlAPI + "training/workout/update/" + id,
            {
              exercicio: {
                carga: carga,
                serie: serie,
                repticoes: repticoes,
                sequencia: sequencia
              }
            },
            {
              headers: {
                authorization: "Bearer " + this.$store.getters.GET_TOKEN
              }
            }
          )
          .then(response => {
            this.$swal.close();
            if (response.data.status) {
              /* dados salvos com sucesso */
              this.$swal({
                icon: "success",
                title: "Sucesso!",
                text: "Carga do Exercicio atualizada!"
              });

              this.$http
                .get(this.$urlAPI + "app/trainings", {
                  headers: {
                    authorization: "Bearer " + this.$store.getters.GET_TOKEN
                  }
                })
                .then(response => {
                  localStorage.setItem(
                    "trainings",
                    JSON.stringify(response.data)
                  );
                })
                .catch(e => {
                  console.log(e);
                });
            } else {
              /* erros de validação */
              let error = "";
              for (const erros of Object.values(response.data.erros)) {
                error += erros + "\n";
              }
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Algo deu errado! " + error
              });
            }
          })
          .catch(e => {
            this.$swal({
              icon: "error",
              title: "Erro!",
              text: e
            });
          });
      } else {
        this.$swal({
          icon: "info",
          title: "Atenção!",
          text: "Você deve estar online para atualizar sua carga do exercicio!"
        });
      }
    }
  }
};
</script>
