<template>
  <div>
    <headerPage
      :title="tipo"
      :btn="true"
    />
    <v-list two-line>
      <div
        v-for="(item, key) in list"
        :key="key"
      >
        <v-list-item>
          <v-list-item-avatar>
            <v-btn
              fab
              dark
              :color="item.nivel == 'I' ? 'blue' : item.nivel == 'T' ? 'yellow' : 'red'"
              :to="'/treinos/info/' + key"
            >
              {{ item.nivel }}
            </v-btn>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              Nível: <strong>{{ item.nivel == 'I' ? 'Iniciante' : item.nivel == 'T' ? 'Intermediário' : 'Avançado' }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              Programa: <strong>{{ item.programa }}</strong>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              :to="'/treinos/info/' + key"
              icon
            >
              <v-icon color="secondary">
                fas fa-chevron-right
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
      </div>
    </v-list>
  </div>
</template>

<script>
  export default {
    components: {
      headerPage: () => import('@/components/headerPage'),
    },
    data: () => ({
      tipo: '',
      list: [],
    }),
    created () {
      this.tipo = this.$route.params.id === 'C' ? 'Treine em Casa' : 'Treine na Academia'
      const itens = JSON.parse(localStorage.getItem('trainings'))
      this.list = itens.list
    },
  }
</script>
