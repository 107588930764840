<template>
  <div>
    <headerPage
      title="Informações da Avaliação"
      :btn="true"
    />
    <v-container>
      <h4>Idade: <strong>{{ avaliacao.idade }} anos</strong></h4>
      <h4>Avaliador(a): <strong>{{ avaliacao.avaliador }}</strong></h4>
      <h4>Realizada em <strong>{{ avaliacao.data_realizacao | moment('YYYY-MM-DD') }}</strong></h4>
      <v-divider class="my-4" />
      <p>
        A prática regular da atividade física, realizada de forma adequada, pode protegê-lo contra o desenvolvimento e a progressão de diversas patologias. Porém, a introdução de qualquer programa de condicionamento deve ser acompanhada sistematicamente, para garantir que a prática da atividade física possa trazer reais benefícios à saúde. Nesse sentido, é necessário que sejam observadas as suas necessidades pessoais, a sua história e as suas condições clínicas/fisiológicas atuais, para que sejam prescritas atividades adequadas e seguras.
      </p>
    </v-container>
    <v-tabs
      v-model="tab"
      background-color="teal darken-2"
      centered
      dark
      next-icon="far fa-caret-square-right"
      prev-icon="far fa-caret-square-left"
      show-arrows
    >
      <v-tabs-slider />
      <v-tab href="#tab-resultado">
        Resultado
      </v-tab>
      <v-tab href="#tab-anamnese">
        Anamnese
      </v-tab>
      <v-tab href="#tab-atividade">
        Ativ. Física
      </v-tab>
      <v-tab href="#tab-fatores">
        Risco Coronariano
      </v-tab>
      <v-tab href="#tab-historico">
        Hist. Méd. Familiar
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-resultado">
        <v-card flat>
          <v-card-text>
            <fieldset>
              <legend>ANTROPOMETRIA</legend>
              <h4><u>Circunferências Musculares</u></h4>
              <br>
              <p>
                Tórax: <strong>{{ avaliacao.torax_antropometria }}cm</strong><br>
                Cintura: <strong>{{ avaliacao.cintura_antropometria }}cm</strong><br>
                Abdomem: <strong>{{ avaliacao.abdomen_antropometria }}cm</strong><br>
                Braço Direito: <strong>{{ avaliacao.braco_direito_antropometria }}cm</strong><br>
                Braço Esquerdo: <strong>{{ avaliacao.braco_esquerdo_antropometria }}cm</strong><br>
                Antebraço Direito: <strong>{{ avaliacao.antebraco_direito_antropometria }}cm</strong><br>
                Antebraço Esquerdo: <strong>{{ avaliacao.antebraco_esquerdo_antropometria }}cm</strong><br>
                Quadril:: <strong>{{ avaliacao.quadril_antropometria }}cm</strong><br>
                Coxa Direita: <strong>{{ avaliacao.coxa_direita_antropometria }}cm</strong><br>
                Coxa Esquerda: <strong>{{ avaliacao.coxa_esquerda_antropometria }}cm</strong><br>
                Perna Direita: <strong>{{ avaliacao.perna_direita_antropometria }}cm</strong><br>
                Perna Esquerda: <strong>{{ avaliacao.perna_esquerda_antropometria }}cm</strong><br>
                Diâmetro Umeral: <strong>{{ avaliacao.umeral_antropometria }}cm</strong><br>
                Diâmetro Femoral: <strong>{{ avaliacao.femoral_antropometria }}cm</strong><br>
                Diâmetro Radio-ulnar: <strong>{{ avaliacao.radio_ulnar_antropometria }}cm</strong>
              </p>
              <br>
              <h4><u>Circunferências Musculares</u></h4>
              <br>
              <p>
                Subescapular: <strong>{{ avaliacao.subescapular_antropometria }}mm</strong><br>
                Tríceps: <strong>{{ avaliacao.triceps_antropometria }}mm</strong><br>
                Bíceps: <strong>{{ avaliacao.bicepes_antropometria }}mm</strong><br>
                Peitoral: <strong>{{ avaliacao.peitoral_antropometria }}mm</strong><br>
                Axilar Média: <strong>{{ avaliacao.axilarMedio_antropometria }}mm</strong><br>
                Supra-ilíaca: <strong>{{ avaliacao.supraIliaca_antropometria }}mm</strong><br>
                Supraespinhal: <strong>{{ avaliacao.supraespinhal_antropometria }}mm</strong><br>
                Abdomem: <strong>{{ avaliacao.abdominal_antropometria }}mm</strong><br>
                Coxa: <strong>{{ avaliacao.coxa_antropometria }}mm</strong><br>
                Perna: <strong>{{ avaliacao.perna_antropometria }}mm</strong>
              </p>
            </fieldset>
            <br>
            <fieldset>
              <legend>CAPACIDADE CÁRDIO-RESPIRATÓRIA</legend>
              <p>
                VO<sup>2</sup> Máx. Absoluto: <strong>l/min</strong><br>
                VO<sup>2</sup> Corrigido pela Idade: <strong>l/min</strong><br>
                VO<sup>2</sup> Máx. Relativo: <strong>mlO<sup>2</sup>/Kg/min</strong><br>
                VO<sup>2</sup> Máx. Previsto: <strong>mlO<sup>2</sup>/Kg/min</strong><br>
                Déficit Aeróbio Funcional: <strong>%</strong><br>
              </p>
            </fieldset>
            <br>
            <fieldset>
              <legend>COMPOSIÇÃO CORPORAL</legend>
              <p>
                O estudo da composição corporal traz subsídios para que sejam conhecidos os efeitos que variáveis como o crescimento, a prática de exercícios, a nutrição e a presença de doenças exercem sobre a morfologia humana. O conhecimento do percentual de gordura corpórea e do peso corporal magro é fator essencial para que possam ser analisadas as modificações que ocorrem nas diferentes estruturas que compôem o corpo humano. A gordura corporal pode ser estimada através do emprego de equações que envolvem a medição das dobras cutâneas ou a medição das cirucnferências musculares.
              </p>

              <h3><strong>Atual</strong></h3>
              <p>
                Peso: <strong>{{ avaliacao.peso_composicao }}Kg</strong><br>
                Densidade: <strong>{{ avaliacao.densidade_corporal }}g/cm³</strong><br>
                %G: <strong>{{ avaliacao.perc_gordura }}%</strong><br>
                MLG:  <strong>{{ avaliacao.mlg }}Kg</strong><br>
                MG:  <strong>{{ avaliacao.mg }}Kg</strong><br>
                Massa Ossea:  <strong>{{ avaliacao.massa_ossea }}Kg</strong><br>
                Massa Muscular:  <strong>{{ avaliacao.massa_muscular }}Kg</strong><br>
                Massa Residual:  <strong>{{ avaliacao.massa_residual }}Kg</strong>
              </p>

              <h3><strong>Alvo</strong></h3>
              <p>
                Peso: <strong>{{ avaliacao.peso_alvo }}Kg</strong><br>
                Densidade: <strong>{{ avaliacao.densidade_corporal_alvo }}g/cm³</strong><br>
                %G: <strong>{{ avaliacao.perc_gordura }}%</strong><br>
                MLG:  <strong>{{ avaliacao.mlg_alvo }}Kg</strong><br>
                MG:  <strong>{{ avaliacao.mg_alvo }}Kg</strong><br>
                Massa Ossea:  <strong>{{ avaliacao.massa_ossea }}Kg</strong><br>
                Massa Muscular:  <strong>{{ avaliacao.massa_muscular_alvo }}Kg</strong><br>
                Massa Residual:  <strong>{{ avaliacao.massa_residual_alvo }}Kg</strong>
              </p>
              <v-divider class="my-3" />
              <p>
                Estatura: <strong>{{ avaliacao.estatura_composicao }}cm</strong><br>
                P. Arterial: <strong>{{ avaliacao.pressao_composicao }}mmHg</strong><br>
                IMC: <strong>{{ avaliacao.imc }}</strong><br>
                Classe IMC: <strong>{{ avaliacao.result_imc }}</strong><br>
                RCQ: <strong>{{ avaliacao.rcq }}</strong><br>
                Classe RCQ: <strong>{{ avaliacao.result_rcq }}</strong>
              </p>
            </fieldset>
            <br>
            <fieldset>
              <legend>METABOLISMO BASAL</legend>
              <p>
                O Metabolismo basal é a energia mínima necessária para manter as funções vitais do organismo de um indivíduo durante 24 horas.
              </p>
              <p>
                Resultado: <strong>{{ avaliacao.metabolismo_basal }}Kcal/dia</strong>
              </p>
            </fieldset>
            <br>
            <fieldset>
              <legend>SOMATOTIPIA</legend>
              <p>
                Endomorfia: <strong>{{ avaliacao.endomorfia_somatotipia }}</strong><br>
                Mesomorfia: <strong>{{ avaliacao.mesomorfia_somatotipia }}</strong><br>
                Ectomorfia: <strong>{{ avaliacao.ectomorfia_somatotipia }}</strong>
              </p>
              <p>
                <strong>{{ avaliacao.result_somatotipia }}</strong>
              </p>
            </fieldset>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-anamnese">
        <v-card flat>
          <v-card-text>
            <ul>
              <li>
                Um médico já lhe disse que sua pressão arterial é muito baixa ou alta?
                <strong>{{ avaliacao.medico_anamnese == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Você sente dor no coração ou no peito?
                <strong>{{ avaliacao.dor_anamnese == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Seu coração bate muitas vezes acelerado?
                <strong>{{ avaliacao.coracao_anamnese == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Algumas vezes você já sentiu seu coração falhar?
                <strong>{{ avaliacao.falhar_anamnese == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Seus tornozelos ficam frequentemente inchados?
                <strong>{{ avaliacao.tornozelos_anamnese == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Seus pés e mãos ficam gelados e trêmulos, mesmo em tempo de calor?
                <strong>{{ avaliacao.pes_maos_anamnese == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Você tem algum comprometimento cardiáco ou alguma alteração no ECG?
                <strong>{{ avaliacao.cardiaco_anamnese == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Você sofre caimbra frequentemente em suas pernas?
                <strong>{{ avaliacao.caimbra_anamnese == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Você ficou com falta de ar muito tempo sem qualquer razão?
                <strong>{{ avaliacao.falta_ar_anamnese == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Alguma vez você já foi internado em hospital?
                <strong>{{ avaliacao.internado_anamnese == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li v-if="avaliacao.internado_anamnese == 'S'">
                Qual foi o motivo de internação?
                <strong>{{ avaliacao.motivo_internado_anamnese || 'Não consta!' }}</strong>
              </li>
              <li>
                Data do último exame físico completo:
                <strong>{{ avaliacao.exame_fisico_anamnese | moment('DD/MM/YYYY') }}</strong>
              </li>
              <li>
                Data do último ECG:
                <strong>{{ avaliacao.ecg_anamnese | moment('DD/MM/YYYY') }}</strong>
              </li>
              <li>
                Relacione qualquer medicamento que esteja em uso:
                <strong>{{ avaliacao.medicamentos_anamnese || 'Não consta!' }}</strong>
              </li>
              <li>
                Relacione qualquer medicamento que você tenha alergia:
                <strong>{{ avaliacao.medicamentos_alergico_anamnese || 'Não consta!' }}</strong>
              </li>
              <li>
                Relacione qualquer suprimento dietético que esteja tomando:
                <strong>{{ avaliacao.suprimentos_anamnese || 'Não consta!' }}</strong>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-atividade">
        <v-card flat>
          <v-card-text>
            <ul>
              <li>
                Você esta normalmente inscrito em um programa de atividade física?
                <strong>{{ avaliacao.inscrito_atfisica == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Você anda ou corre 1,6km ou mais continuamente?
                <strong>{{ avaliacao.anda_corre_atfisica == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Participa frequentemente de esporte competitivo?
                <strong>{{ avaliacao.esporte_comp_atfisica == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Participa frequentemente de esporte com características de lazer?
                <strong>{{ avaliacao.esporte_lazer_atfisica == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-fatores">
        <v-card flat>
          <v-card-text>
            <ul>
              <li>
                É fumante?
                <strong>{{ avaliacao.fumante_coraniano == 'S' ? 'Sim' : avaliacao.fumante_coraniano == 'P' ? 'Parei' : 'Nunca Fumei' }}</strong>
              </li>
              <li v-if="avaliacao.fumante_coraniano == 'S'">
                Quantos cigarros fuma por dia?
                <strong>{{ avaliacao.cigarros_coraniano }}unds</strong>
              </li>
              <li v-if="avaliacao.fumante_coraniano == 'P'">
                Se você parou de fumar, quando foi?
                <strong>{{ avaliacao.parou_fumar_coraniano }}</strong>
              </li>
              <li>
                Está fazendo dieta?
                <strong>{{ avaliacao.dieta_coraniano == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li v-if="avaliacao.dieta_coraniano == 'S'">
                Caso esteja fazendo dieta, a base de que?
                <strong>{{ avaliacao.dieta_base_coraniano }}</strong>
              </li>
              <li>
                Quanto você considera um bom peso para você?
                <strong>{{ avaliacao.peso_vc_coraniano }}kg</strong>
              </li>
              <li>
                Qual o máximo de peso que você já teve?
                <strong>{{ avaliacao.max_peso_vc_coraniano }}kg</strong>
              </li>
              <li>
                Com que idade?
                <strong>{{ avaliacao.idade_coraniano }} anos</strong>
              </li>
              <li>
                Qual o número de refeições que faz por dia?
                <strong>{{ avaliacao.refeicoes_coraniano }} refeições</strong>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-historico">
        <v-card flat>
          <v-card-text>
            <p>
              <strong>Alguns de seus familiares teve algumas das seguintes doenças?</strong>
            </p>
            <ul>
              <li>
                Ataque cardíaco abaixo dos 50 anos:
                <strong>{{ avaliacao.ataque_cardiaco_historico == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Acidente cerebral abaixo dos 50 anos:
                <strong>{{ avaliacao.acidente_cerebral_historico == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Pressão Alta:
                <strong>{{ avaliacao.pressao_alta_historico == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Doença Cardiaca:
                <strong>{{ avaliacao.cardiaca_historico == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Diabetes:
                <strong>{{ avaliacao.diabetes_historico == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Asma:
                <strong>{{ avaliacao.asma_historico == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Glaucoma:
                <strong>{{ avaliacao.glaucoma_historico == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Obesidade (20kg ou mais acima do peso):
                <strong>{{ avaliacao.obesidade_historico == 'S' ? 'Sim' : 'Não' }}</strong>
              </li>
              <li>
                Parecer Final:
                <strong>{{ avaliacao.parecer_historico }}</strong>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  export default {
    components: {
      headerPage: () => import('@/components/headerPage'),
    },
    data () {
      return {
        tab: null,
        avaliacao: {},

      }
    },
    created () {
      const localItem = JSON.parse(localStorage.getItem('assessments'))
      this.avaliacao = localItem[this.$route.params.id]
    },
  }
</script>

<style>
  fieldset{
    padding: 0 20px;
    border: 1px solid rgba(0, 0, 0, .15);
  }
  fieldset legend{
    padding: 0 10px;
    font-weight: bold;
  }
</style>
